import { useRouter } from 'next/router';
import { isNil } from 'ramda';
import { FunctionComponent, useEffect, useState } from 'react';

import { Tooltip } from 'components';

import DynamicIcon from 'components/DynamicIcon';
import SmartLink from 'components/SmartLink';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { useTracking } from 'hooks/useTracking';

import { TrackingEventAction } from 'utils/constants/trackingEvents';

import { NavItem } from 'types/menu';

import ArrowDropdown from 'assets/icons/arrow-dropdown.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';

interface NavbarProps {
  items: NavItem[];
}

const Navbar: FunctionComponent<NavbarProps> = ({ items }) => {
  const { pathname, query } = useRouter();
  const [navItems, setNavItems] = useState(items);
  const [open, setOpen] = useState(false);
  const { trackEvent } = useTracking();

  const { featureFlagData } = useFeatureFlags([
    'menu_showAsnMenu_underConstruction',
    'menu_show_Integration_menu_under_construction',
  ]);

  useEffect(() => {
    if (items?.length) {
      setNavItems(items);
    }
  }, [items]);

  const handleCollapseMenu = (index): void => {
    const items = [...navItems];
    const item = { ...items[index], expand: !items[index].expand };
    items[index] = item;
    setNavItems(items);
  };

  const isActivePath = (pathname, item) => {
    return pathname === item.path || item.path === query?.referer;
  };

  const buildItemsNavbar = (arrayItems, subItem?: boolean) => {
    return arrayItems.map((item, index) => {
      const { children } = item;

      if (!isNil(item.visible) && item.visible === false) {
        return;
      }

      if (item.children) {
        if (
          !featureFlagData.menu_showAsnMenu_underConstruction &&
          item.id === 'asn'
        ) {
          return null;
        }

        if (
          !featureFlagData.menu_show_Integration_menu_under_construction &&
          item.id === 'integrations'
        ) {
          return null;
        }

        return (
          <li
            className={`min-height-80 ${item.extraClass ?? ''}`}
            key={item.label}
          >
            <div className="border-b border-gray-100 flex flex-col items-center w-full">
              {open && (
                <div
                  className="flex flex-col justify-center items-center w-full cursor-pointer relative py-2"
                  onClick={() => handleCollapseMenu(index)}
                >
                  <div className="flex items-center w-full cursor-pointer relative px-7 h-10">
                    <div className="flex items-center">
                      <label className="text-sm font-aeonik-medium text-gray-900 cursor-pointer">
                        {item.label}
                      </label>
                    </div>
                    <div className="absolute right-5">
                      <ArrowDropdown
                        className={`fill-current text-gray-800 ${
                          item.expand ? 'open-menu' : 'close-menu'
                        }`}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`flex justify-center items-center w-full ${
                  item.expand ? 'expand-item-drawer' : 'collapsed-item-drawer'
                }`}
              >
                {item.expand || !open ? (
                  <ul className="m-0 p-0 w-full">
                    {buildItemsNavbar(children, true)}
                  </ul>
                ) : null}
              </div>
            </div>
          </li>
        );
      } else if (!item.children) {
        return (
          <li
            className={`border-solid border-gray-100 flex items-center w-full ${
              !subItem ? 'border-b' : 'py-2'
            } ${item.extraClass ?? ''}`}
            key={item.path}
            data-tooltip-id={item.label}
            data-tooltip-position-strategy="fixed"
            onClick={() =>
              trackEvent(TrackingEventAction.NAV, {
                context: item.label,
              })
            }
          >
            <SmartLink className="w-full" {...item}>
              <span
                className={`group flex h-10 items-center w-full cursor-pointer ${
                  isActivePath(pathname, item) ? 'bg-primary-active' : ''
                } ${item.isDisable ? 'pointer-events-none' : ''}`}
              >
                <div
                  className={`border-2 flex h-10 rounded-r-md ${
                    isActivePath(pathname, item)
                      ? 'border-primary-60'
                      : 'border-transparent'
                  }`}
                ></div>
                <div
                  className={`flex items-center pl-6 ${
                    item.isDisable ? 'opacity-50' : ''
                  }`}
                >
                  <div
                    className={`primary-icon ${
                      isActivePath(pathname, item) ? 'color-primary-active' : ''
                    }`}
                  >
                    <DynamicIcon iconName={item?.icon?.icon} />
                  </div>
                  {open && (
                    <label
                      className={`ml-2.5 text-sm transition-transform duration-500 cursor-pointer ${
                        isActivePath(pathname, item)
                          ? 'text-primary-60'
                          : 'text-gray-600'
                      }`}
                    >
                      {item.label}
                    </label>
                  )}
                </div>
              </span>
            </SmartLink>
            {!open && (
              <Tooltip id={item.label} place="right" preset="dark">
                <div className="px-3 py-2 font-aeonik text-center rounded text-sm">
                  {item.label}
                </div>
              </Tooltip>
            )}
          </li>
        );
      }
    });
  };

  return (
    <nav
      className={`flex flex-col h-full relative font-aeonik justify-between w-20 shadow ${
        open ? 'drawer-open' : 'drawer-close'
      }`}
    >
      <ul className="m-0 p-0 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        {buildItemsNavbar(navItems)}
      </ul>
      <div
        onClick={() => setOpen(!open)}
        className={`border-t border-solid border-gray-100 cursor-pointer flex h-14 items-center w-full ${
          open ? 'justify-end pr-7' : 'justify-center'
        }`}
      >
        <ArrowRight
          className={`primary-icon ${open ? 'open-menu' : 'close-menu'}`}
        />
      </div>
    </nav>
  );
};

export default Navbar;
