import {
  Fragment,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTable, useRowSelect } from 'react-table';

import { ErrorText, checkColumnPush } from 'components';

import NoResults from 'components/NoResults';

import { NOT_FOUND_MESSAGES } from 'utils/constants/noResultsFound';
import { OrdersStatus } from 'utils/enums';

import ArrowUpward from 'assets/icons/arrow-upward.svg';

interface RowConfigProps {
  data: RowsProps;
  medatadata?: RowMetaDataProps;
}

export enum SortMethod {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface SortByColumn {
  isActive: boolean;
  method: SortMethod;
  isSortable: boolean;
  filterName: string;
}

export interface Column {
  Header: ReactNode;
  accessor: string;
  sortByColumn?: SortByColumn;
  className?: string;
  tooltipMessage?: string;
  headerType?: HeaderCellType;
}

export enum HeaderCellType {
  NUMBER,
}

export interface RowsProps {
  [key: string]: unknown;
}

export interface RowMetaDataProps {
  className: string;
}

export type RowMode = 'compact' | 'default';

export interface TableProps {
  columns: Column[];
  data: Array<RowsProps | RowConfigProps>;
  className?: string;
  errorColumn?: string;
  selectRows?: boolean;
  scrollOnUpdate?: boolean;
  hideColumn?: string;
  variant?: 'default' | 'dark';
  setSelectedRows?([]): void;
  onClickColumn?: (index: number) => void;
  setHeaders?: (value: SetStateAction<Column[]>) => void;
  setSortFilter?: (value: SetStateAction<string>) => void;
  setSortMethod?: (value: SetStateAction<SortMethod>) => void;
  noResultsTemplate?: ReactNode;
  rowMode?: RowMode;
  containerClass?: string;
}

const Table: FunctionComponent<TableProps> = ({
  columns,
  data,
  selectRows = false,
  scrollOnUpdate = true,
  className = '',
  errorColumn = '',
  hideColumn,
  onClickColumn,
  setSelectedRows,
  setHeaders,
  setSortFilter,
  setSortMethod,
  variant = 'default',
  noResultsTemplate,
  rowMode = 'default',
  containerClass = '',
}) => {
  const { getTableProps, headers, rows, prepareRow, selectedFlatRows } =
    useTable({ columns, data }, useRowSelect, hooks =>
      selectRows ? checkColumnPush(hooks) : null
    );
  const [rowsResults, setRowsResults] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map(row => row.original));
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (scrollOnUpdate && data && data.length) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [data]);

  useEffect(() => {
    if (rows && rows.length) {
      setRowsResults(rows.length);
    }
  }, [rows.length]);

  const onSortByColumn = (id: string) => {
    setHeaders(prevState => {
      let sortMethod: SortMethod;
      let sortFilter: string;

      const updatedList = prevState.map(header => {
        if (header.accessor === id) {
          switch (header.sortByColumn.method) {
            case undefined:
              sortMethod = SortMethod.DESC;
              sortFilter = header.sortByColumn?.filterName;
              break;

            case SortMethod.DESC:
              sortMethod = SortMethod.ASC;
              sortFilter = header.sortByColumn?.filterName;
              break;

            case SortMethod.ASC:
              sortMethod = undefined;
              sortFilter = undefined;
              break;
          }

          return {
            ...header,
            sortByColumn: {
              ...header.sortByColumn,
              isActive: sortMethod ? true : false,
              method: sortMethod,
              isSortable: true,
            },
          };
        } else {
          return {
            ...header,
            sortByColumn: {
              ...header.sortByColumn,
              isActive: false,
              method: undefined,
            },
          };
        }
      });

      setSortFilter?.(sortFilter);
      setSortMethod?.(sortMethod);
      return updatedList;
    });
  };

  return (
    <div
      className={`h-full overflow-x-auto ${containerClass}`}
      ref={containerRef}
    >
      {rowsResults && (
        <table
          className={`w-full border-separate border-spacing-0 table-auto sturdy min-w-max ${className}`}
          {...getTableProps()}
        >
          <thead>
            <tr>
              {headers.map((column, index) => (
                <th
                  key={`${column.Header}+${index}`}
                  className={`sticky bg-white z-10 group select-none font-aeonik px-6 text-left h-12.5 text-body-14 text-neutral-100 top-0 ${
                    column?.sortByColumn?.isSortable && 'cursor-pointer'
                  } ${
                    variant === 'dark'
                      ? 'bg-mvs-bg-primary border-b border-mvs-border-lighter'
                      : 'border-t bg-white border-b border-neutral-40'
                  } ${column?.className ? column?.className : 'pl-6'} ${
                    column.id == 'selection' ? 'px-0 pl-3' : ''
                  }
                  `}
                  {...column.getHeaderProps()}
                  onClick={() => {
                    if (!column?.sortByColumn?.isSortable) return;
                    onSortByColumn(column.id);
                  }}
                >
                  <div
                    className={`flex items-center ${
                      column?.headerType === HeaderCellType.NUMBER
                        ? column?.sortByColumn?.isSortable
                          ? '-mr-4 justify-end'
                          : 'justify-end'
                        : 'text-left'
                    }`}
                  >
                    <span
                      className={
                        column?.sortByColumn?.isActive ? 'text-primary-60' : ''
                      }
                    >
                      {column.render('Header')}
                    </span>
                    {column?.sortByColumn?.isSortable && (
                      <ArrowUpward
                        className={`group-hover:visible ml-1 ${
                          column?.sortByColumn && column?.sortByColumn?.isActive
                            ? 'visible fill-primary-60'
                            : 'invisible fill-neutrals-200'
                        } ${
                          column?.sortByColumn?.method === SortMethod.ASC
                            ? 'close-menu'
                            : 'open-menu'
                        }`}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              prepareRow(row);

              const fontBold = row?.original?.has_font_bold;
              return (
                <Fragment key={`${row[index]}.${index}`}>
                  <tr
                    key={`${row[index]}.${index}`}
                    className={`relative h-4 ${
                      onClickColumn ? 'cursor-pointer' : 'cursor-default'
                    }`}
                    {...row.getRowProps()}
                    {...row?.original?.metadata}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      const isHide = cell.column.Header === hideColumn;
                      const columnId = cell.column.id;
                      const rowData = row?.original?.data;
                      const hasError =
                        cell.column.Header === errorColumn &&
                        row?.values?.status?.props?.status ===
                          OrdersStatus.Error;

                      return (
                        <td
                          {...cell.getCellProps()}
                          key={`${cell.value}+${cellIndex}`}
                          className={`${
                            rowMode === 'compact' ? 'h-8' : 'h-10'
                          } border-solid text-body-14 leading-[1.375rem]  whitespace-pre-wrap ${
                            fontBold
                              ? 'font-medium text-neutrals-100 bg-neutrals-10'
                              : 'text-neutral-80'
                          } ${
                            onClickColumn ? 'cursor-pointer' : 'cursor-default'
                          } ${
                            variant === 'dark'
                              ? 'border-b border-mvs-border-lighter'
                              : 'border-b border-neutral-40'
                          } ${cell?.column?.className ? cell?.column?.className : 'pl-6'} ${
                            columnId == 'selection' ? 'px-0 pl-3' : ''
                          }`}
                          onClick={e => {
                            if (
                              onClickColumn &&
                              (e.target as HTMLInputElement | null)?.type !==
                                'checkbox'
                            ) {
                              onClickColumn(index);
                            }
                          }}
                        >
                          <div
                            className={`flex text-body-14 cursor-pointer ${
                              cell.column?.headerType === HeaderCellType.NUMBER
                                ? 'pr-6 justify-end'
                                : ''
                            } ${hasError ? 'text-yellow-40' : ''}
                                    ${isHide ? 'invisible hover:visible' : ''} 
                                  `}
                          >
                            {!hasError ? (
                              rowData ? (
                                rowData[columnId]
                              ) : (
                                cell.render('Cell')
                              )
                            ) : (
                              <ErrorText
                                text={
                                  rowData
                                    ? rowData[columnId]
                                    : cell.render('Cell')
                                }
                              />
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
      {rows.length === 0 &&
        (noResultsTemplate ? (
          noResultsTemplate
        ) : (
          <NoResults
            title={NOT_FOUND_MESSAGES.RECORDS_NOT_FOUND.title}
            description={NOT_FOUND_MESSAGES.RECORDS_NOT_FOUND.description}
          />
        ))}
    </div>
  );
};

export default Table;
