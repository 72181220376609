import { IParty } from 'types/party';

export interface OrderStatusItem {
  createdAt: string;
  description: string;
  id: string;
  name: OrdersStatus;
  orderId: string;
  processedBy: IParty;
  updatedAt: string;
}

export enum OrdersStatus {
  Allocated = 'ALLOCATED',
  Canceled = 'CANCELED',
  Fulfilled = 'FULFILLED',
  PartiallyShipped = 'PARTIALLY_SHIPPED',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Ordered = 'ORDERED',
  Pending = 'PENDING',
  Shipped = 'SHIPPED',
  Error = 'ERROR',
  Draft = 'DRAFT',
}
