import { gql } from '@apollo/client';

const PRODUCT_COLORS_LIST_QUERY = gql`
  query Colors($brandId: ID) {
    colors(brandId: $brandId) {
      value: id
      title: name
    }
  }
`;

const PRODUCT_SIZES_LIST_QUERY = gql`
  query Sizes($brandId: ID) {
    sizes(brandId: $brandId) {
      value: id
      title: name
    }
  }
`;

const PRODUCT_STYLES_LIST_QUERY = gql`
  query Styles($brandId: ID) {
    styles(brandId: $brandId) {
      value: id
      title: name
    }
  }
`;

const BRANDS_LIST_QUERY = gql`
  query brands($companyId: ID) {
    brands(companyId: $companyId) {
      value: id
      title: name
    }
  }
`;

const ADD_COLOR_MUTATION = gql`
  mutation addColor($input: AddColorInput!) {
    addColor(input: $input) {
      id
    }
  }
`;

const ADD_SIZE_MUTATION = gql`
  mutation addSize($input: AddSizeInput!) {
    addSize(input: $input) {
      id
    }
  }
`;

const ADD_STYLE_MUTATION = gql`
  mutation addStyle($input: AddStyleInput!) {
    addStyle(input: $input) {
      id
    }
  }
`;

export {
  PRODUCT_COLORS_LIST_QUERY,
  PRODUCT_SIZES_LIST_QUERY,
  PRODUCT_STYLES_LIST_QUERY,
  BRANDS_LIST_QUERY,
  ADD_COLOR_MUTATION,
  ADD_SIZE_MUTATION,
  ADD_STYLE_MUTATION,
};
