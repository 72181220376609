import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { IFeatureFlagEnabled } from 'service/generated/graphql';

import { FEATURE_FLAG_QUERY } from 'api/featureFlag';

interface FeatureFlagEnabled {
  [key: string]: boolean;
}

const useFeatureFlags = (flagList: string[]) => {
  const [featureFlags, setFeatureFlags] = useState<Array<IFeatureFlagEnabled>>(
    []
  );
  const [featureFlagData, setFeatureFlagData] = useState<FeatureFlagEnabled>(
    {}
  );

  const { data: featureFlagsResponse, loading: loadingFeatureFlag } = useQuery<{
    ffe: Array<IFeatureFlagEnabled>;
  }>(FEATURE_FLAG_QUERY, {
    variables: {
      keys: flagList,
    },
  });

  useEffect(() => {
    if (featureFlagsResponse?.ffe) {
      const responseFlags = featureFlagsResponse.ffe;
      setFeatureFlags(responseFlags);
      const entries = Object.fromEntries(
        responseFlags.map(item => [item.flagKey, item.enabled])
      );
      setFeatureFlagData(entries);
    }
  }, [featureFlagsResponse]);

  return {
    featureFlags,
    loadingFeatureFlag,
    featureFlagData,
  };
};

export default useFeatureFlags;
