/* eslint-disable @typescript-eslint/ban-types */

import { ErrorMessage, Field, FormikErrors } from 'formik';
import {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';

import { Spinner as SpinnerLoader } from 'components/Icons/Spinner';

import { getClosestScrollableElement } from 'utils/functions/getClosestScrollableElement';

import styles from './styles.module.css';

const DEFAULT_BOX_SIZES = {
  width: 200,
  height: 160,
};

export enum SearchInputTheme {
  MATERIAL,
  CUSTOM,
}

export interface formikFieldHelpers {
  setFieldValue: (
    field: string,
    value: string
  ) => Promise<FormikErrors<unknown>> | Promise<void>;
  handleBlur: (name: string) => void;
  setFieldTouched: (name: string, touchedValued) => void;
}

export type InputChangePayload = {
  fieldValue: string;
  form: formikFieldHelpers;
  fieldArrayIndex: number;
};

export interface InputSearchProps<T> {
  title?: string;
  name: string;
  options: T[];
  minimumInputLength?: number;
  loading: boolean;
  theme?: SearchInputTheme;
  value?: string;
  className?: string;
  width?: number;
  placeholder?: string;
  height?: number;
  fieldArrayIndex?: number;
  labelFieldName?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  onOptionSelected?: (option: T) => void;
  onInputChange: (payload: InputChangePayload) => void;
  onInputBlur?: (payload: InputChangePayload) => void;
  renderOptionItem?: (option: T) => JSX.Element;
}

const InputSearch = <T extends {}>(props: InputSearchProps<T>) => {
  const {
    title,
    name,
    options,
    minimumInputLength = 3,
    loading,
    placeholder,
    height,
    fieldArrayIndex = 0,
    width = 300,
    value = '',
    theme = SearchInputTheme.CUSTOM,
    className = '',
    labelFieldName = '',
    disabled = false,
    icon,
    getOptionLabel,
    onInputChange,
    onInputBlur,
    getOptionValue,
    renderOptionItem,
    onOptionSelected,
  } = props;

  const [isActive, setActive] = useState(false);
  const [optionListPosition, setOptionListPosition] = useState({});
  const [inputValue, setInputValue] = useState(value);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();
  const node = useRef<HTMLInputElement>(undefined);
  const searchThemeClass = {
    [SearchInputTheme.MATERIAL]: {
      input: styles.textBox,
      bar: styles.bar,
      tag: styles.tag,
    },
    [SearchInputTheme.CUSTOM]: {
      input: '',
      bar: '',
      tag: '',
    },
  };
  const currentThemeStyle = searchThemeClass[theme];

  const boxStyle = {
    maxWidth: width ?? DEFAULT_BOX_SIZES.width,
    maxHeight: height ?? DEFAULT_BOX_SIZES.height,
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    form: formikFieldHelpers,
    name: string
  ) => {
    form.setFieldTouched(name, true);
    setInputValue(e.target.value);
    const fieldValue = e.target.value.trim();
    if (fieldValue.length >= minimumInputLength) {
      setActive(true);
      setSelectedOptionIndex(0);
      onInputChange({
        fieldArrayIndex,
        fieldValue,
        form,
      });
    }
  };

  const handleBlur = (
    e: ChangeEvent<HTMLInputElement>,
    form: formikFieldHelpers,
    name: string
  ) => {
    form.handleBlur(name);
    const fieldValue = e.target.value.trim();
    setActive(false);
    setSelectedOptionIndex(undefined);
    onInputBlur &&
      onInputBlur({
        fieldArrayIndex,
        fieldValue,
        form,
      });
  };

  const handleOptionSelected = (option: T) => {
    setInputValue('');
    onOptionSelected && onOptionSelected(option);
    setActive(false);
  };

  const isFieldTouched = meta => {
    return meta.touched && meta.error;
  };

  const updateListPosition = () => {
    const { top, left, height, width } = node.current.getBoundingClientRect();

    let optionListTop = top + height;
    let itemsPosition = 'flex-start';
    if (optionListTop + boxStyle.maxHeight > window.innerHeight) {
      optionListTop = top - boxStyle.maxHeight;
      itemsPosition = 'flex-end';
    }

    setOptionListPosition({
      width,
      left,
      top: optionListTop,
      alignItems: itemsPosition,
    });
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    const pageClickEvent = e => {
      if (node.current !== null && !node.current.contains(e.target)) {
        setActive(false);
      }
    };
    if (isActive) {
      updateListPosition();
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  useEffect(() => {
    if (node.current && isActive) {
      updateListPosition();
    }
  }, [loading]);

  useEffect(() => {
    const handleScroll = () => {
      if (node.current !== null) {
        updateListPosition();
      }
    };

    const parentScrollingElement = getClosestScrollableElement(node.current);
    if (parentScrollingElement) {
      parentScrollingElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      parentScrollingElement.removeEventListener('scroll', handleScroll);
    };
  }, [node]);

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement>,
    form: formikFieldHelpers,
    name: string
  ) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedOptionIndex(prevIndex =>
        prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedOptionIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && selectedOptionIndex !== -1) {
      e.preventDefault();
      const selectedOption = options[selectedOptionIndex];
      form.setFieldValue(name, getOptionValue(selectedOption));
      handleOptionSelected(selectedOption);
      if (labelFieldName) {
        form.setFieldValue(labelFieldName, getOptionLabel(selectedOption));
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setSelectedOptionIndex(undefined);
      setActive(false);
    }

    const selectedListItem = document.getElementById(
      `option-${selectedOptionIndex}`
    );

    selectedListItem?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  };

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <div className="relative w-full">
          {icon && (
            <div className="absolute flex h-full items-center pl-3">{icon}</div>
          )}

          <input
            ref={node}
            autoComplete="off"
            value={inputValue}
            onFocus={() => {
              setActive(true);
              setSelectedOptionIndex(-1);
            }}
            onChange={e => handleChange(e, form, name)}
            onBlur={e => handleBlur(e, form, name)}
            onKeyDown={e => handleKeyDown(e, form, name)}
            disabled={disabled}
            placeholder={`${placeholder ? placeholder : 'Search'}`}
            className={`${currentThemeStyle.input} ${className} ${
              icon ? 'pl-10' : ''
            } font-aeonik`}
          />
          <input type="hidden" {...field} />
          {theme === SearchInputTheme.MATERIAL && (
            <>
              <span
                className={`${isFieldTouched(meta) ? styles.invalid : ''} ${
                  currentThemeStyle.bar
                }`}
              ></span>
              <label className={`${currentThemeStyle.tag} font-aeonik`}>
                {title}
              </label>
              <span className="absolute block">
                <ErrorMessage
                  name={name}
                  component="span"
                  className="block text-xs text-danger-40"
                />
              </span>
            </>
          )}
          <div
            className={`fixed flex z-40 items-start ${
              isActive ? 'block' : 'hidden'
            }`}
            style={{
              minWidth: boxStyle.maxWidth,
              height: boxStyle.maxHeight,
              ...optionListPosition,
            }}
          >
            <div className="shadow bg-white top-100 w-full lef-0 rounded max-h-select overflow-y-auto">
              {loading ? (
                <li className="flex justify-center p-2">
                  <SpinnerLoader />
                </li>
              ) : (
                <>
                  {!disabled && (
                    <ul
                      className="overflow-y-auto"
                      style={{
                        maxHeight: boxStyle.maxHeight,
                      }}
                    >
                      {options.map((option, index) => (
                        <Fragment key={index}>
                          <li
                            id={`option-${index}`}
                            className={`cursor-pointer hover:bg-gray-50 p-2 border border-b border-neutrals-20 ${
                              selectedOptionIndex === index ? 'bg-gray-50' : ''
                            }`}
                            onMouseDown={() => {
                              form.setFieldValue(name, getOptionValue(option));
                              handleOptionSelected(option);
                              if (labelFieldName) {
                                form.setFieldValue(
                                  labelFieldName,
                                  getOptionLabel(option)
                                );
                              }
                            }}
                          >
                            <div className="text-body-14 min-h-10 text-neutral-80 pl-3 whitespace-pre-wrap flex flex-col">
                              {renderOptionItem
                                ? renderOptionItem(option)
                                : getOptionLabel(option)}
                            </div>
                          </li>
                        </Fragment>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Field>
  );
};

export default InputSearch;
