import { gql } from '@apollo/client';

export const FEATURE_FLAG_QUERY = gql`
  query FeatureFlagEnabled($keys: [String!]!) {
    ffe(keys: $keys) {
      flagKey
      enabled
    }
  }
`;
