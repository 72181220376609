import { isNil } from 'ramda';
import { FunctionComponent, ReactNode, useState, useEffect } from 'react';

import ExpandMore from 'assets/icons/expand_more.svg';

interface CollapsibleProps {
  id?: string;
  title?: JSX.Element | string;
  className?: string;
  children: ReactNode;
  expanded?: boolean;
  theme?: CollapsibleTheme;
  header?: JSX.Element;
  disabledEvents?: boolean;
  onChange?: (id: string, open: boolean) => void;
}

export enum CollapsibleTheme {
  COLLAPSIBLE,
  SUB_COLLAPSIBLE,
}

const Collapsible: FunctionComponent<CollapsibleProps> = ({
  id = '',
  title,
  children,
  onChange,
  className = '',
  header,
  expanded = false,
  theme = CollapsibleTheme.COLLAPSIBLE,
  disabledEvents = false,
}) => {
  const [isOpen, setIsOpen] = useState(expanded);
  const toggle = () => {
    if (disabledEvents) return;
    setIsOpen(!isOpen);
    onChange && onChange(id, !isOpen);
  };

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  const themeClass = {
    [CollapsibleTheme.COLLAPSIBLE]: {
      button:
        'py-3 bg-background border-y font-semibold text-lg font-aeonik-medium',
    },
    [CollapsibleTheme.SUB_COLLAPSIBLE]: {
      button: 'pt-7 pb-3 bg-white border-b text-base',
    },
  };

  const currentThemeStyle = themeClass[theme];

  return (
    <div className={className}>
      <button
        type="button"
        className={`w-full flex justify-between items-center px-8 text-left text-mvs-text-primary ${
          currentThemeStyle.button
        } ${disabledEvents ? 'cursor-auto' : ''}`}
        onClick={toggle}
      >
        {!isNil(header) ? header : <span>{title}</span>}
        {!disabledEvents && (
          <span
            className={`transition duration-200 ${isOpen ? 'rotate-180' : ''} `}
          >
            <ExpandMore />
          </span>
        )}
      </button>
      {isOpen && <div className="px-8">{children}</div>}
    </div>
  );
};

export default Collapsible;
